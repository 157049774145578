import { Outlet } from 'react-router-dom';
import Container from '../../components/Container/Container';
import FAQ from '../../components/FAQ/FAQ';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import S from './RootPage.styles';

const RootPage = () => {
  return (
    <main>
      <Header />
      <Container>
        <S.FirstSection>
          <S.LeftColumn>
              <Outlet></Outlet>
          </S.LeftColumn>
          <img src="iphone.png" />
        </S.FirstSection>
      </Container>
      <FAQ />
      <Footer />
    </main>
  );
};

export default RootPage;
