import styled from 'styled-components';

const Logo = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 50px;

  img {
    width: 170px;
  }

  @media (max-width: ${({ theme }) => theme.tablet}) {
    justify-content: center;
  }
`;

export default { Logo };
