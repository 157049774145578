import styled from 'styled-components';

const LeftColumn = styled.div`
  max-width: 40%;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.tablet}) {
    max-width: 100%;
    text-align: center;
  }
`;

const FirstSection = styled.div`
  display: flex;
  margin-top: 50px;
  padding-bottom: 100px;
  > * {
    align-self: center;
  }

  img {
    max-width: 60%;
    height: auto;
    margin: 30px 0;
  }

  fieldset {
    border: 0;
    padding: 0;
    margin: 0;
  }

  @media (max-width: ${({ theme }) => theme.tablet}) {
    flex-direction: column-reverse;
    margin-top: 40px;
    align-items: center;
  }
`;


export default { LeftColumn, FirstSection };
