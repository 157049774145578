import styled from 'styled-components';

const Background = styled.footer`
  background-color: ${({ theme }) => theme.colors.main};
`;

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.main};
  display: flex;
  justify-content: space-between;
  height: 100px;
  align-items: center;
  color: white;

  a {
    color: white;
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    flex-direction: column;
    padding: 20px 0;
  }
`;

const Copyright = styled.p`
  font-size: 15px;
  font-weight: 300;
  line-height: 15px;
  letter-spacing: 0px;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
`;

const Icon = styled.img`
  height: 24px;
  &:hover {
    cursor: pointer;
  }
`;

const Logo = styled.img`
  height: 30px;
  margin-left: 20px;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin: 0;
  }
`;

const Links = styled.div`
  display: flex;
  justify-content: space-between;
  margin-right: 20px;

  a {
    margin: 0 5px;
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin: 0;
  }
`;

export default { Container, Copyright, Icon, Logo, Links, Background };
