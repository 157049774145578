import styled from 'styled-components';

const H1 = styled.h1`
  color: ${({ theme }) => theme.colors.main};
  font-size: 60px;
  line-height: 50px;
  font-weight: 600;
  margin-bottom: 0px;
`;

const H2 = styled.h2`
  color: ${({ theme }) => theme.colors.main};
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 0px;

  @media (max-width: ${({ theme }) => theme.tablet}) {
    font-size: 35px;
  }
`;

const H3 = styled.h3`
  color: ${({ theme }) => theme.colors.main};
  font-size: 50px;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: 0px;
  margin-bottom: 0px;
`;

const Highlighted = styled.p`
  font-size: 30px;
  line-height: 36px;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.main};
  margin-bottom: 0px;
`;

const P = styled.p`
  color: ${({ theme }) => theme.colors.greyDark};
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 50px;
`;

export default { H1, H2, H3, Highlighted, P };
