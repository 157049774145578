import Container from '../Container/Container';
import S from './header.styles';

const Header = () => {
  return (
    <Container>
      <S.Logo>
        <a href="/">
          <img src="./exlabs-logo.svg" />
        </a>
      </S.Logo>
    </Container>
  );
};

export default Header;
