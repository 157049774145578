import S from './Loader.styles';

const Loader = () => {
    return (
        <S.Ellipsis>
            <div />
            <div />
            <div />
            <div />
        </S.Ellipsis>
    );
};

export default Loader;
