import H from '../../components/Headlines/headlines.styles';

const SuccessPage = () => {
  return (
    <div>
      <H.H1>🤞 The tool is almost ready</H.H1>
      <H.P>
        Thank you for your interest in our breach detection tool. We are still in closed beta and promise to get back to you with more information as soon as possible.
      </H.P>
    </div>
  );
};

export default SuccessPage;
