import styled from 'styled-components';
import H from '../Headlines/headlines.styles';

const Wrapper = styled.div<{  isLoading: boolean }>`
  position: relative;

  h1 img {
    width: 60px;
    vertical-align: center;
    margin: 0;
  }
  form {
    opacity: ${props => props.isLoading ? 0 : 1};
  }
`;

const Loading = styled.div`
  position: absolute;
  top: -20px;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255,255,255,0.8);

`;

const Img = styled.svg`
  height: auto;
  width: 60%;

  @media (max-width: ${({ theme }) => theme.tablet}) {
    width: 100%;
    max-height: 300px;
  }
`;

const H1 = styled(H.H1)`
  @media (max-width: ${({ theme }) => theme.mobile}) {
    font-size: 50px;
    line-height: 50px;
  }
`;

const Subheadline = styled.h2`
  color: ${({ theme }) => theme.colors.main};
  font-weight: 300;
  font-size: 30px;
`;

const Email = styled.input.attrs({ 
  name: 'email',
  type: 'email',
})`
  width: 100%;
  display: block;
  max-width: 400px;
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid rgba(0,0,0,0.5);
  margin-bottom: 5px;
  margin-top: 5px;
  box-sizing: border-box;

  @media (max-width: ${({ theme }) => theme.tablet}) {
    margin: 0 auto;
  }
`;

const EmailLabel = styled.label`
  display: block;
  font-weight: 600;
`;

const PrivacyPolicy = styled.p`
  max-width: 400px;
  font-size: .8em;
  margin-bottom: 30px;
  font-weight: 300;

  @media (max-width: ${({ theme }) => theme.tablet}) {
    text-align: center;
    max-width: none;
  }
`;

const Logo = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 50px;

  img {
    width: 170px;
  }

  @media (max-width: ${({ theme }) => theme.tablet}) {
    justify-content: center;
  }
`;

const Button = styled.input.attrs({ 
  type: 'submit',
  value: 'Get a trap!',
})`
  background-color: ${(props) =>
    props.color === 'secondary' ? props.theme.colors.secondary : props.theme.colors.main};
  padding: 20px 40px 16px;
  border-radius: 20px;
  font-weight: ${(props) => props.color === 'secondary' && 700};
  font-size: 20px;
  color: ${({ theme }) => theme.colors.white};
  border: none;
  cursor: pointer;

  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 0.8;
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: 15px 20px 12px;
  }
`;

const LoaderWrapper = styled.div`
  position: absolute;
  margin-top: 10px;
`;

export default { Wrapper, Loading, Img, Logo, H1, Subheadline, Email, EmailLabel, PrivacyPolicy, Button, LoaderWrapper };
