import React from 'react';
import { hydrate, render } from 'react-dom';
import { ThemeProvider } from 'styled-components';
import './animations.scss';
import './index.scss';

import {
  createBrowserRouter,
  RouterProvider,
  // Route,
} from 'react-router-dom';
import RootPage from './page/RootPage/RootPage';
// import HomePage from './page/HomePage/HomePage';
import LegalPage from './page/LegalPage/LegalPage';

import privacyPolicyPath from './page/LegalPage/privacyPolicy.md';
import termsOfServicePolicyPath from './page/LegalPage/termsOfService.md';
import HomePage from './page/HomePage/HomePage';
import SuccessPage from './page/SuccessPage/SuccessPage';

const root = document.getElementById('root') as HTMLElement;

const theme = {
  colors: {
    main: '#1D4BA9',
    secondary: '#FF4F61',
    bg: '#E6EFFC',
    bgSecondary: '#EAEAEA',
    white: '#FFF',
    grey: '#E8E8E8',
    lightGrey: '#D9D9D9',
    greyMid: '#5F5F5F',
    greyDark: '#2D2D2D',
  },
  mobile: '768px',
  tablet: '1000px',
  desktop: '1140px',
};

const router = createBrowserRouter([
  {
    path: '/',
    element: <RootPage />,
    children: [
      {
        path: '/',
        element: <HomePage />,
      },
      {
        path: '/success',
        element: <SuccessPage />,
      },
    ],
  },
  {
    path: '/privacy',
    element: <LegalPage markdownPath={privacyPolicyPath} />,
  },
  {
    path: '/terms_of_service',
    element: <LegalPage markdownPath={termsOfServicePolicyPath} />,
  },
]);

const App = () => (
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>
);

if (root.hasChildNodes()) {
  hydrate(<App />, root);
} else {
  render(<App />, root);
}
