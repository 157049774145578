import styled, { css } from 'styled-components';

const Wrapper = styled.div<{ full?: boolean; bottomShadow?: boolean }>`
  margin: 0 auto;
  max-width: ${({ theme }) => theme.desktop};
  padding: 0 20px;

  ${({ bottomShadow }) =>
    bottomShadow &&
    css`
      margin-bottom: 50px;
      &::after {
        content: '';
        width: 100%;
        height: 100px;
        left: 0;
        position: absolute;
        margin-top: 50px;
        display: block;
        box-shadow: 0px -50px 50px rgba(0, 0, 0, 0.02);
      }
    `};

  ${({ full }) =>
    full &&
    css`
      max-width: 100%;
      padding: 0;
    `};
`;

export default { Wrapper };
