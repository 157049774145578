import Container from '../Container/Container';
import H from '../Headlines/headlines.styles';
import S from './faq.styles';

const content = [
  {
    header: 'How does it work?',
    text: 'BreachBeagle generates fake database credentials that attract hacker\'s attention. Once the attacker tries to access our honeypot, we notify you about potential breach in you system.',
  },
  {
    header: 'How do I install it?',
    text: 'BreachBeagle installation is dead simple. All you have to do is add our fake postgres configuration to your config file, such as AWS Lambda Secrets, .ENV file, etc.',
  },
  {
    header: 'Is it secure?',
    text: 'Our service cannot read your Cloud configuration or access your IT premises. It can only passively listen to incoming traffic and terminate all connection attempts. If you wish to self-host solution for greater control, reach out to us!',
  },
  {
    header: 'What is a lateral movement?',
    text: 'Lateral movement is a technique used by cyberattackers who, after gaining initial access, search for sensitive data and other high-value assets, often with a support of automated scanners.',
  },
];

interface ColumnProps {
  header: string;
  text: string;
}

const OneColumn = ({ header, text }: ColumnProps) => {
  return (
    <S.OneColumn>
      <S.CircleH4>{header}</S.CircleH4>
      <S.CircleText>{text}</S.CircleText>
    </S.OneColumn>
  );
};

const FAQ = () => {
  return (
    <Container full>
      <S.Background>
        <Container>
          <S.FAQ>
            <H.H2>FAQ</H.H2>
            <S.List>
              {content.map((el, idx) => (
                <OneColumn header={el.header} text={el.text} key={idx} />
              ))}
            </S.List>
            <S.Contact>
              Still have a question? 
              <a href="mailto:team@exlabs.com">Contact us:</a>
              <S.Email>team@exlabs.com</S.Email>
            </S.Contact>
          </S.FAQ>
        </Container>
      </S.Background>
    </Container>
  );
};

export default FAQ;
