import styled from 'styled-components';

const Background = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  background: ${({ theme }) => theme.colors.bg};
  padding-bottom: 250px;
  margin-top: 100px;

  ::before {
    content: url('./BgTop.svg');
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    transform: translateY(-90%);
    left: 0;
    z-index: -1;
  }
`;

const CircleH4 = styled.h4`
  font-size: 25px;
  font-weight: 600;
  margin: 20px 0;

  color: ${({ theme }) => theme.colors.greyDark};

  ::before {
    content: '‣';
    margin-right: 10px;
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    height: 100%;
  }
`;

const CircleText = styled.p`
  color: ${({ theme }) => theme.colors.greyMid};
  font-size: 20px;
  line-height: 1.5em;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin-top: 0px;
  }
`;

const FAQ = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const List = styled.div`
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const OneColumn = styled.div`
  padding: 0 40px 0 0;
  flex: 50%;
  box-sizing: border-box;

  @media (max-width: ${({ theme }) => theme.tablet}) {
    flex: 100%;
    padding: 0 20px;
  }
`;

const Contact = styled.div`
  margin-top: 80px;
  color: ${({ theme }) => theme.colors.greyMid};

  a {
    margin: 0 5px;
    color: ${({ theme }) => theme.colors.greyMid};
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    text-align: center;
  }
`;

const Email = styled.span`
  font-weight: 600;
`;

export default {
  Background,
  CircleH4,
  CircleText,
  FAQ,
  OneColumn,
  List,
  Contact,
  Email,
};
