import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown';

import Container from '../../components/Container/Container';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';
import S from './LegalPage.styles';

interface LegalPageProps {
  markdownPath: string;
}

interface LegalPageState {
  text: string;
}


class LegalPage extends Component <LegalPageProps, LegalPageState> {
  constructor(props: LegalPageProps) {
    super(props);
    this.state = { text: '' };
  }

  shouldComponentUpdate() {
    console.log('rerender?');
    return true;
  }

  componentDidMount() {
    fetch(this.props.markdownPath).then((response) => response.text()).then((text) => {
      this.setState({ text: text });
    });
  }

  componentDidUpdate() {
    fetch(this.props.markdownPath).then((response) => response.text()).then((text) => {
      this.setState({ text: text });
    });
  }

  render() {
    return (
      <main>
        <Header />
        <Container>
          <S.PrivacyContainer>
            <ReactMarkdown>
              {this.state.text}
            </ReactMarkdown>
          </S.PrivacyContainer>
        </Container>
        <Footer />
      </main>
    );
  }
}

export default LegalPage;
