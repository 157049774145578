import styled from 'styled-components';

const Main = styled.main`
  display: flex;
  flex-direction: column;
`;

const PrivacyContainer = styled.div`
  margin-bottom: 100px;
  margin-top: 100px;
  max-width: 800px;

  p {
    line-height: 1.5em;
  }

  p+p {
    margin-top: 20px;
  }

  h1, h2, h3, h3, h4 {
    color: ${({ theme }) => theme.colors.main};
  }
`;

export default { PrivacyContainer, Main };
