// import FAQ from '../../components/FAQ/FAQ';
// import Footer from '../../components/Footer/Footer';
// import Header from '../../components/Header/Header';
import Hero from '../../components/Hero/Hero';

const HomePage = () => {
  return (
    <Hero />
  );
};

export default HomePage;
