import { ReactNode } from 'react';
import S from './Container.styles';

const Container = ({
  children,
  full,
  bottomShadow,
  ...rest
}: {
  children: ReactNode;
  full?: boolean;
  bottomShadow?: boolean;
}) => {
  return (
    <S.Wrapper full={full} bottomShadow={bottomShadow} {...rest}>
      {children}
    </S.Wrapper>
  );
};

export default Container;
