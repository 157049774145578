import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import Container from '../Container/Container';
import H from '../Headlines/headlines.styles';
import Loader from '../Loader/Loader';
import S from './hero.styles';


const Hero = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  function encode(data: { [key: string]: string }) {
    return Object.keys(data)
      .map(
        (key) =>
          encodeURIComponent(key) + '=' + encodeURIComponent(data[key]),
      )
      .join('&');
  }

  const handleSubmit = function (event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setLoading(true);

    const formName = event.currentTarget.getAttribute('name');
    const emailInput = event.currentTarget.querySelector('input[type=email]') as HTMLInputElement;
    
    if (emailInput === null) {
      return;
    }

    const email = emailInput.value;
    if (formName === null || email === null || email.length === 0) {
      setLoading(false);
      return;
    }

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': formName,
        'email': email,
      }),
    }).then(function (response) {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response;
      })
      .then(() => {
        console.log('SUCCESS');
        navigate('/success');
      })
      .catch((error) => {
        alert(error);
      });
  };

  return (
    <S.Wrapper isLoading={loading}>
      <S.H1>
        <img src="beagle.svg" /> BreachBeagle
      </S.H1>
      <S.Subheadline>A free tool monitoring access to infrastructure configuration</S.Subheadline>
      <H.P>
        Don’t miss unauthorised access to your IT premises.
        Hide fake database credentials that spot hackers’ attention. Get notification whenever someone tries to use them.
      </H.P>
      { loading && <S.LoaderWrapper><Loader /></S.LoaderWrapper>}
      <form data-netlify="true" name="registration" onSubmit={handleSubmit}>
        <fieldset disabled={loading}>
          <input type="hidden" name="form-name" value="registration" />
          <S.EmailLabel>
            Your Email
            <S.Email />
          </S.EmailLabel>
          <S.PrivacyPolicy>By signing up you accept our Privacy Policy</S.PrivacyPolicy>
          
          <S.Button />
        </fieldset>
      </form>
    </S.Wrapper>
  );
};

export default Hero;
