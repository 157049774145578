import Container from '../Container/Container';
import S from './footer.styles';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <S.Background>
            <Container>
                <S.Container>
                    <div>
                        Build with 🛡️ by <a href="https://exlabs.com">Exlabs</a>
                    </div>
                    <S.Links>
                        <Link to={'/privacy'}>Privacy Policy</Link>
                        <Link to={'/terms_of_service'}>Terms of Service</Link>
                    </S.Links>
                </S.Container>
            </Container>
        </S.Background>
    );
};

export default Footer;
